import { IconAlertHexagon, IconBox } from "@tabler/icons-react";
import { useForm } from "@tanstack/react-form";
import {
  infiniteQueryOptions,
  queryOptions,
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { format, parse, subDays } from "date-fns";
import { z } from "zod";

import {
  clampValue,
  downloadCSV,
  exhaustList,
  sortField,
} from "@joy/shared-utils";

import { useValidators } from "../../hooks";
import { addressLookupSchema, geocode } from "../address";
import { useAuth } from "../auth";
import { Word } from "../helpers";
import { request, requestFn } from "./base";
import {
  TankAlertsDocument,
  TankDocument,
  TankLevelsDocument,
  TankRolesDocument,
  TankUnit,
  TanksDocument,
  UpdateTankDocument,
} from "./operations.generated";

export const tank: Word = {
  icon: IconBox,
  article: "a",
  singular: "tank",
  plural: "tanks",
};

export const alert: Word = {
  icon: IconAlertHexagon,
  article: "an",
  singular: "alert",
  plural: "alerts",
};

export const tankUnitOptions: Record<TankUnit, string> = {
  l: "Litres",
  gal: "Gallons",
};

export const tankLevelStyle = (
  possible: number | null | undefined,
  product: { colour: string } | null | undefined,
) => {
  const percent = clampValue(possible, [0, 100]);
  const height = `${percent || 0}%`;
  const colour = product?.colour || "#10B981";

  return { percent, height, colour };
};

export const tanksQuery = (enable = true) =>
  infiniteQueryOptions({
    queryKey: ["tanks"],
    queryFn: ({ pageParam }) =>
      request(TanksDocument, { limit: 500, cursor: pageParam || null }),
    getNextPageParam: (lastPage) => lastPage?.tanks.next,
    initialPageParam: "",
    enabled: enable,
    select: (data) =>
      sortField(
        data.pages.flatMap((p) =>
          p.tanks.tanks.map((t) => ({
            ...t,
            level: t.levels.levels[0],
          })),
        ),
        {
          key: "updatedAt",
          dir: "desc",
          unique: "id",
        },
      ),
  });

export type TankItem = ReturnType<
  NonNullable<ReturnType<typeof tanksQuery>["select"]>
>[number];

export const tankQuery = (id: string) =>
  queryOptions({
    queryKey: ["tank", id],
    queryFn: () => request(TankDocument, { id }),
    select: (data) => ({
      ...data.tank!,
      level: data.tank?.levels.levels[0],
      permissions: data.tankAccess,
    }),
  });

export const tankLevelsQuery = (id: string, daysAgo?: number) =>
  infiniteQueryOptions({
    enabled: !!daysAgo,
    queryKey: ["tank-levels", id, daysAgo].filter(Boolean),
    queryFn: ({ pageParam }) =>
      request(TankLevelsDocument, {
        id,
        startAt: subDays(new Date(), daysAgo || 0),
        limit: 1000,
        cursor: pageParam || null,
      }),
    getNextPageParam: (lastPage) => lastPage?.tank?.levels.next,
    initialPageParam: "",
    select: (data) =>
      sortField(
        data.pages.flatMap((p) => p.tank?.levels.levels || []),
        { key: "updatedAt", dir: "asc" },
      ),
  });

export const tankAlertsQuery = (id: string) =>
  infiniteQueryOptions({
    queryKey: ["tank-alerts", id],
    queryFn: ({ pageParam }) =>
      request(TankAlertsDocument, {
        id,
        limit: 50,
        cursor: pageParam || null,
      }),
    getNextPageParam: (lastPage) => lastPage?.tank?.alerts.next,
    initialPageParam: "",
    select: (data) => data.pages.flatMap((p) => p.tank?.alerts.alerts || []),
  });

export const tankRolesQuery = (tankId: string) =>
  infiniteQueryOptions({
    queryKey: ["tank-roles", tankId],
    queryFn: ({ pageParam }) =>
      request(TankRolesDocument, {
        tankId,
        limit: 50,
        cursor: pageParam || null,
      }),
    getNextPageParam: (lastPage) => lastPage?.tankRoles.next,
    initialPageParam: "",
    select: (data) =>
      data.pages.flatMap((p) => p.tankRoles.roles.map((r) => r.user)),
  });

const validation = {
  name: z.string().min(1, "Please enter a tank name"),
  unit: z.enum(["l", "gal"]).nullable(),
  location: addressLookupSchema.nullable(),
  alertUsers: z.array(
    z.object(
      { id: z.string(), email: z.string().email() },
      { invalid_type_error: "Please select a user" },
    ),
  ),
};

const updateTankFn = requestFn(UpdateTankDocument);

const useTankMutation = (tankId: string) => {
  const navigate = useNavigate();
  const { data } = useSuspenseQuery(tankQuery(tankId));
  const queryClient = useQueryClient();
  const update = useMutation({
    mutationFn: updateTankFn,
    onSuccess: async (result) => {
      queryClient.setQueryData(
        tankQuery(result.updateTank.id).queryKey,
        (existing) => {
          if (!existing) return undefined;
          return {
            ...existing,
            tank: result.updateTank,
          };
        },
      );
      queryClient.setQueryData(tanksQuery().queryKey, (existing) => {
        if (!existing) return undefined;

        return {
          ...existing,
          pages: existing.pages.map((p) => ({
            ...p,
            tanks: {
              ...p.tanks,
              tanks: p.tanks.tanks.map((u) => {
                if (u.id === result.updateTank?.id) return result.updateTank;
                return u;
              }),
            },
          })),
        };
      });
      await navigate({ to: "/tanks/$tankId", params: { tankId } });
    },
  });

  return { data, update };
};

export const useUpdateTank = (tankId: string) => {
  const {
    data,
    update: { error, mutateAsync, reset },
  } = useTankMutation(tankId);

  const form = useForm({
    defaultValues: {
      name: data.name || "",
      activatedAt: data.activatedAt
        ? format(data.activatedAt, "yyyy-MM-dd")
        : "",
      unit: data.unit || null,
      product: data.tankProduct || null,
      location: data.location
        ? {
            type: "result" as "result" | "suggestion",
            id: "existing",
            ...data.location,
          }
        : null,
    },
    onSubmit: async ({
      value: { product, activatedAt, location, ...fields },
    }) => {
      await mutateAsync({
        id: tankId,
        fields: {
          ...fields,
          productId: product?.id || null,
          activatedAt: activatedAt ? parse(activatedAt, "yyyy-MM-dd", 0) : null,
          location: await geocode.retrieve(location),
        },
      });
    },
    onSubmitInvalid: () => reset(),
  });
  const validators = useValidators(validation, form.state.submissionAttempts);

  return { data, error, form, validators };
};

export const defaultAlertLevel = { threshold: 0, polarity: "below" as const };

export const defaultAlertRate = {
  threshold: 0,
  polarity: "falling" as const,
  period: 15,
};

export const useUpdateTankAlerts = (tankId: string) => {
  const {
    data,
    update: { error, mutateAsync, reset },
  } = useTankMutation(tankId);

  const form = useForm({
    defaultValues: {
      alertLevels: data.alertLevels || [defaultAlertLevel],
      alertRates: data.alertRates || [defaultAlertRate],
      alertUsers: data.alertUsers || [],
    },
    onSubmit: async ({ value }) => {
      await mutateAsync({
        id: tankId,
        fields: {
          alertLevels: value.alertLevels.filter((l) => l.threshold > 0),
          alertRates: value.alertRates.filter((l) => l.threshold > 0),
          alertUsers: value.alertUsers.map((u) => u.email),
        },
      });
    },
    onSubmitInvalid: () => reset(),
  });
  const validators = useValidators(validation, form.state.submissionAttempts);

  return { data, error, form, validators };
};

const downloadLevelsFn = async ({
  id,
  daysAgo,
}: {
  id: string;
  daysAgo: number;
}) => {
  const startAt = subDays(new Date(), daysAgo);

  const levels = await exhaustList(async (cursor) => {
    const result = await request(TankLevelsDocument, {
      id,
      startAt,
      cursor,
      limit: 500,
    });
    return {
      items: result.tank?.levels.levels || [],
      next: result.tank?.levels.next || undefined,
    };
  });

  return sortField(levels, { key: "updatedAt", dir: "asc" });
};

export const useDownloadTankHistory = (tankId: string) => {
  const { hasTeamPermission } = useAuth();
  const navigate = useNavigate();
  const { data } = useSuspenseQuery(tankQuery(tankId));
  const { error, mutateAsync, reset } = useMutation({
    mutationFn: downloadLevelsFn,
    onSuccess: async (levels) => {
      downloadCSV(levels, {
        cells: [
          "updatedAt",
          "value",
          "maximum",
          "percent",
          "voltage",
          "temperature",
          "signal",
          "rssi",
          "src",
        ],
        headers: {
          updatedAt: "Time",
          value: "Level",
          maximum: "SFL",
          percent: "Level Percent",
          voltage: "Battery Percent",
          temperature: "Temperature",
          signal: "Signal",
          rssi: "RSSI",
          src: "SRC",
        },
        formats: {
          updatedAt: "dateTime",
        },
        visibility: {
          rssi: hasTeamPermission("admin"),
          src: hasTeamPermission("admin"),
        },
        file: `${data.name.toLocaleLowerCase().split(" ").filter(Boolean).join("-")}-history`,
      });
      await navigate({ to: "/tanks/$tankId", params: { tankId } });
    },
  });
  const form = useForm({
    defaultValues: {
      daysAgo: 28,
    },
    onSubmit: async ({ value }) => {
      await mutateAsync({ id: tankId, daysAgo: value.daysAgo });
    },
    onSubmitInvalid: () => reset(),
  });

  return { data, error, form };
};
