import { IconDots } from "@tabler/icons-react";
import clsx from "clsx";

import { mapOrEmpty, specialChars } from "@joy/shared-utils";

import {
  ComboField,
  Field,
  Label,
  Menu,
  cellKinds,
  cellVariants,
  tableParts,
  text,
} from "../../components";
import { UploadGaugesContentProps, customer, customersQuery } from "../../data";
import { useComboQuery } from "../../hooks";

export const GaugeUploadItems = ({ form }: UploadGaugesContentProps) => {
  const customers = useComboQuery(customersQuery(), { word: customer });

  return (
    <>
      <form.Field
        name="customer"
        children={(field) => (
          <ComboField
            field={field}
            label="Customer"
            accessors={[(o) => o.name]}
            optionKey={(o) => o.id}
            optionLabel={(o) => o.name}
            immediate
            {...customers.combo}
          />
        )}
      />
      <form.Field
        name="valid"
        mode="array"
        children={(list) => (
          <Field>
            <Label>Gauges to create ({list.state.value.length}):</Label>
            <table className={tableParts.table}>
              <thead>
                <tr>
                  <th className={clsx(text, cellKinds.title)}>IMEI</th>
                  <th className={clsx(text, cellKinds.title)}>Product</th>
                  <th className={clsx(text, cellKinds.title)}>Model</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {mapOrEmpty(
                  list.state.value,
                  (_, idx) => (
                    <form.Field
                      key={idx}
                      name={`valid[${idx}]`}
                      children={(field) => (
                        <tr>
                          <td className={clsx(cellVariants.base, "px-3 py-2")}>
                            {field.state.value.id}
                          </td>
                          <td className={clsx(cellVariants.base, "px-3 py-2")}>
                            {field.state.value.fields.technology ||
                              specialChars.endash}{" "}
                            (
                            {field.state.value.fields.product ||
                              specialChars.endash}
                            )
                          </td>
                          <td className={clsx(cellVariants.base, "px-3 py-2")}>
                            {[
                              field.state.value.fields.name,
                              field.state.value.fields.model,
                            ]
                              .filter(Boolean)
                              .join(" | ") || specialChars.endash}
                          </td>
                          <td className={clsx(cellVariants.base, "px-3 py-2")}>
                            <Menu
                              anchor="bottom end"
                              button={{
                                icon: IconDots,
                                kind: "menu",
                                variant: "standard",
                              }}
                              items={[
                                {
                                  text: "Remove item",
                                  onClick: () => list.removeValue(idx),
                                },
                              ]}
                            />
                          </td>
                        </tr>
                      )}
                    />
                  ),
                  <tr>
                    <td
                      colSpan={4}
                      className={clsx(cellVariants.base, "px-3 py-6")}
                    >
                      <p className="text-center font-semibold text-gray-400 italic">
                        No gauges to create.
                      </p>
                    </td>
                  </tr>,
                )}
              </tbody>
            </table>
          </Field>
        )}
      />
      <form.Field
        name="invalid"
        mode="array"
        children={(list) =>
          !!list.state.value.length && (
            <Field>
              <Label>Invalid gauges ({list.state.value.length}):</Label>
              <table className={tableParts.table}>
                <thead>
                  <tr>
                    <th className={clsx(text, cellKinds.title)}>Row</th>
                    <th className={clsx(text, cellKinds.title)}>Error</th>
                  </tr>
                </thead>
                <tbody>
                  {list.state.value.map((_: any, idx: number) => (
                    <form.Field
                      key={idx}
                      name={`invalid[${idx}]`}
                      children={(field) => (
                        <tr>
                          <td className={clsx(cellVariants.base, "px-3 py-2")}>
                            {field.state.value.label}
                          </td>
                          <td
                            className={clsx(
                              cellVariants.base,
                              "px-3 text-red-700",
                            )}
                          >
                            {field.state.value.error}
                          </td>
                        </tr>
                      )}
                    />
                  ))}
                </tbody>
              </table>
            </Field>
          )
        }
      />
    </>
  );
};
