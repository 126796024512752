import ky from "ky";
import { z } from "zod";

import { config } from "../config";
import {
  Address,
  AddressLookup,
  AddressResult,
  AddressSuggestion,
} from "./util";

const mapbox = ky.extend({
  prefixUrl: "https://api.mapbox.com/search/searchbox/v1/",
  searchParams: {
    access_token: config.mapToken,
  },
});

const itemsSchema = z.object({
  features: z.array(
    z.object({
      properties: z.object({
        mapbox_id: z.string(),
        full_address: z.string(),
        coordinates: z.object({
          latitude: z.number(),
          longitude: z.number(),
        }),
      }),
    }),
  ),
});

const suggestionSchema = z.object({
  suggestions: z.array(
    z.object({ mapbox_id: z.string(), full_address: z.string() }),
  ),
});

export const coords = /(-?\d+.?\d*),(-?\d+.?\d*)/;

const reverse = async ({
  latitude,
  longitude,
}: {
  latitude: number;
  longitude: number;
}): Promise<AddressResult[]> => {
  try {
    const result = await mapbox
      .get("reverse", {
        searchParams: { types: "address", latitude, longitude },
      })
      .json();
    const parsed = itemsSchema.parse(result);

    return parsed.features.map((f) => ({
      type: "result",
      id: f.properties.mapbox_id,
      display: f.properties.full_address,
      latitude: f.properties.coordinates.latitude,
      longitude: f.properties.coordinates.longitude,
    }));
  } catch {
    return [];
  }
};

export const liveGeocode = {
  reverse,
  suggest: async (
    search: string,
    session: string,
    proximity?: Promise<{ latitude: number; longitude: number }>,
  ): Promise<(AddressResult | AddressSuggestion)[]> => {
    try {
      if (search.match(coords)) {
        const [num1, num2] = search.split(",").map(parseFloat);
        const first = await reverse({
          latitude: num1!,
          longitude: num2!,
        });
        const second = await reverse({
          latitude: num2!,
          longitude: num1!,
        });

        const results = [...first, ...second];
        return results.length
          ? results
          : [
              {
                type: "result",
                id: `${num1}.${num2}`,
                display: `${num1}, ${num2}`,
                latitude: num1 || 0,
                longitude: num2 || 0,
              },
            ];
      }

      const searchParams: Record<string, string> = {
        session_token: session,
        types: "address",
        q: search,
      };
      try {
        if (proximity) {
          const location = await proximity;
          searchParams.proximity = `${location.longitude},${location.latitude}`;
        }
      } catch {
        /* works witouth proximity */
      }
      const result = await mapbox.get("suggest", { searchParams }).json();
      const parsed = suggestionSchema.parse(result);

      return parsed.suggestions.map((suggestion) => ({
        session,
        type: "suggestion",
        display: suggestion.full_address,
        id: suggestion.mapbox_id,
        latitude: 0,
        longitude: 0,
      }));
    } catch {
      return [];
    }
  },
  retrieve: async (
    suggestion: AddressLookup | null | undefined,
  ): Promise<Address | null> => {
    if (!suggestion) return null;

    if (suggestion.type === "result")
      return {
        display: suggestion.display,
        latitude: suggestion.latitude,
        longitude: suggestion.longitude,
      };

    const result = await mapbox
      .get(`retrieve/${suggestion.id}`, {
        searchParams: {
          session_token: suggestion.session || "",
        },
      })
      .json();
    const parsed = itemsSchema.parse(result);
    if (!parsed.features[0])
      throw new Error(`No features found for ${suggestion.id}`);

    const item = parsed.features[0].properties;
    return {
      display: item.full_address,
      latitude: item.coordinates.latitude,
      longitude: item.coordinates.longitude,
    };
  },
};
