import { AnyFieldApi } from "@tanstack/react-form";

import { Editor } from "../editor";
import type { EditorProps } from "../editor/input";
import { Field, FieldError, Label, fieldKinds } from "../forms";

export const EditorField = ({
  field,
  label,
  fieldKind,
  ...rest
}: {
  field: AnyFieldApi;
  label: string;
  fieldKind?: keyof typeof fieldKinds;
} & Omit<EditorProps, "initialValue" | "onChange">) => (
  <Field kind={fieldKind}>
    <Label htmlFor={field.name.toString()}>{label}</Label>
    <Editor
      initialValue={field.state.value}
      onChange={(value) => field.handleChange(value)}
      {...rest}
    />
    <FieldError field={field} />
  </Field>
);
