import { useForm } from "@tanstack/react-form";
import {
  useInfiniteQuery,
  useMutation,
  useMutationState,
} from "@tanstack/react-query";
import { useState } from "react";
import { z } from "zod";

import { isElementOf, isValueOf } from "@joy/shared-utils";

import { useValidators } from "../../hooks";
import { Column, Workbook } from "../helpers";
import { requestFn } from "./base";
import { GaugeItem, gaugesQuery } from "./gauges";
import {
  CreateGaugeDocument,
  CreateGaugeMutationVariables,
  GaugeProduct,
  GaugeTechnology,
} from "./operations.generated";

const validation = {
  upload: z.instanceof(File, { message: "Please select a file" }),
  sheet: z.string().min(1, "Please select a sheet"),
  column: z.object(
    { type: z.enum(["column", "static", "empty"]), value: z.string() },
    {
      invalid_type_error: "Please select a column",
      required_error: "Please select a column",
    },
  ),
};

type UploadGaugeFields = {
  upload: File | null;
  workbook: Workbook | null;
  sheet: string;
  mappedSheet: string;
  columns: Record<
    "id" | "name" | "product" | "technology" | "model",
    Column | null
  >;
  customer: { id: string; name: string } | null;
  valid: CreateGaugeMutationVariables[];
  invalid: { label: string; error: string }[];
};

export const defaultUploadGaugeFields: UploadGaugeFields = {
  upload: null,
  workbook: null,
  sheet: "",
  mappedSheet: "",
  columns: {
    id: null,
    name: null,
    product: null,
    technology: null,
    model: null,
  },
  customer: null,
  valid: [],
  invalid: [],
};

const buildGaugeForUpload = (
  row: Record<string, string>,
  {
    columns,
    gauges,
    customer,
  }: {
    columns: UploadGaugeFields["columns"];
    customer: UploadGaugeFields["customer"];
    gauges: GaugeItem[];
  },
): UploadGaugeFields["valid"][number] => {
  const id =
    (columns.id?.type === "column" && row[columns.id.value]) || undefined;
  if (!id) throw new Error("No IMEI found");
  const name =
    (columns.name?.type === "column" && row[columns.name.value]) || undefined;

  const inputProduct =
    (columns.product?.type === "column" && row[columns.product.value]) ||
    (columns.product?.type === "static" && columns.product.value) ||
    undefined;
  if (!inputProduct) throw new Error("No product selected");
  if (!isElementOf(["Cippus", "Tekelek"] as const, inputProduct))
    throw new Error(`Invalid product: ${inputProduct}`);

  const technology =
    (columns.technology?.type === "column" && row[columns.technology.value]) ||
    (columns.technology?.type === "static" && columns.technology.value) ||
    undefined;
  if (!technology) throw new Error("No technology selected");
  if (!isValueOf(GaugeTechnology, technology))
    throw new Error(`Invalid technology: ${technology}`);

  let product: GaugeProduct | undefined;
  if (
    inputProduct === "Cippus" &&
    isElementOf(
      ["Pressure", "Temperature", "TemperatureAndPressure"] as const,
      technology,
    )
  )
    product = "Cippus";
  if (
    inputProduct === "Tekelek" &&
    isElementOf(["ADC", "Radar", "Ultrasonic"] as const, technology)
  ) {
    product = `Tek${technology}`;
  }
  if (!product)
    throw new Error(
      `Invalid product & technology: ${inputProduct}, ${technology}`,
    );

  let model;
  let config;
  let strappingTable;

  const inputModel =
    (columns.model?.type === "column" && row[columns.model.value]) ||
    (columns.model?.type === "static" && columns.model.value) ||
    undefined;
  if (inputModel) {
    const gauge = gauges.find((g) => g.model === inputModel);
    if (!gauge) throw new Error(`Model not found: ${inputModel}`);
    model = gauge.model;
    strappingTable = gauge.strappingTable;
    if (gauge.technology === technology) config = gauge.config;
  }

  const customerId = customer?.id;

  return {
    id,
    fields: {
      name,
      product,
      technology,
      model,
      config,
      strappingTable,
      customerId,
    },
  };
};

const createGaugeFn = requestFn(CreateGaugeDocument);

export const useUploadGauges = () => {
  const gauges = useInfiniteQuery(gaugesQuery());
  const [step, setStep] = useState(1);
  const [mutationKey] = useState(() => ["createGauge", crypto.randomUUID()]);

  const { mutate } = useMutation({
    mutationKey,
    mutationFn: createGaugeFn,
  });
  const mutations = useMutationState({
    filters: { mutationKey },
    select: (mutation) => ({
      variables: mutation.state.variables as CreateGaugeMutationVariables,
      status: mutation.state.status,
      error: mutation.state.error,
    }),
  });

  const form = useForm({
    defaultValues: defaultUploadGaugeFields,
    onSubmit: async ({ value, formApi }) => {
      switch (step) {
        case 1: {
          const valid: UploadGaugeFields["valid"] = [];
          const invalid: UploadGaugeFields["invalid"] = [];
          for (const row of value.workbook?.data[value.sheet]?.rows || []) {
            try {
              valid.push(
                buildGaugeForUpload(row, {
                  customer: value.customer,
                  columns: value.columns,
                  gauges: gauges.data || [],
                }),
              );
            } catch (error) {
              invalid.push({
                label: row.__rowNum__,
                error: error instanceof Error ? error.message : "Invalid",
              });
            }
          }
          formApi.setFieldValue("valid", valid);
          formApi.setFieldValue("invalid", invalid);

          setStep(2);
          break;
        }
        case 2: {
          const { valid } = value;
          for (const gauge of valid) {
            mutate(gauge);
          }
          setStep(3);
          break;
        }
      }
    },
  });
  const validators = useValidators(validation, form.state.submissionAttempts);

  return {
    form,
    gauges,
    mutations,
    step,
    setStep: (i: number) => i < step && setStep(i),
    validators,
  };
};

export type UploadGaugesContentProps = Pick<
  ReturnType<typeof useUploadGauges>,
  "form" | "gauges" | "mutations" | "validators"
>;
