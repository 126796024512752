import { IconKey } from "@tabler/icons-react";
import { useForm } from "@tanstack/react-form";
import {
  infiniteQueryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import QRCode from "qrcode-with-logos";
import { useState } from "react";
import { z } from "zod";

import { hashedId } from "@joy/shared-utils";

import { logo } from "../../assets";
import { useClearParams, useValidators } from "../../hooks";
import { Word } from "../helpers";
import { request, requestFn } from "./base";
import {
  ApiKeyEntityType,
  ApiKeysDocument,
  CreateApiKeyDocument,
  DeleteApiKeyDocument,
} from "./operations.generated";

export const apiKey: Word = {
  icon: IconKey,
  article: "a",
  singular: "key",
  plural: "keys",
};

export const apiKeysQuery = (entityId: string, entityType: ApiKeyEntityType) =>
  infiniteQueryOptions({
    queryKey: ["api-keys", entityId],
    queryFn: ({ pageParam }) =>
      request(ApiKeysDocument, {
        entityId,
        entityType,
        limit: 50,
        cursor: pageParam || null,
      }),
    getNextPageParam: (lastPage) => lastPage?.apiKeys.next,
    initialPageParam: "",
    enabled: !!entityId,
    select: (data) => data.pages.flatMap((p) => p.apiKeys.apiKeys),
  });

const validation = {
  label: z.string().min(1, "Label is required"),
  usage: z.enum(["API", "QR"], {
    invalid_type_error: "Please select a usage type",
  }),
};

const createApiKeyFn = requestFn(CreateApiKeyDocument);

export const useCreateApiKey = ({
  entityId,
  entityType,
}: {
  entityId: string;
  entityType: ApiKeyEntityType;
}) => {
  const [saved, setSaved] = useState<{
    apiKey: string;
    qrCode?: string;
    label: string;
  }>();
  const queryClient = useQueryClient();
  const { error, mutateAsync, reset } = useMutation({
    mutationFn: createApiKeyFn,
    onSuccess: async () => {
      queryClient.invalidateQueries(apiKeysQuery(entityId, entityType));
    },
  });

  const form = useForm({
    defaultValues: {
      label: "",
      usage: "API",
    },
    onSubmit: async ({ value }) => {
      const id = `${value.usage}-${crypto.randomUUID()}`;
      let qrCode;
      if (value.usage === "QR") {
        const code = new QRCode({
          logo: {
            src: logo,
          },
          width: 600,
          content: `${window.location.origin}/qr?code=${id}`,
          nodeQrCodeOptions: {
            errorCorrectionLevel: "Q",
          },
          cornersOptions: {
            type: "rounded",
          },
        });
        const canvas = await code.getCanvas();
        qrCode = canvas.toDataURL("image/png");
      }

      await mutateAsync({
        id: await hashedId(id),
        fields: {
          label: value.label,
          maskedValue: [
            id.slice(0, 6),
            id.slice(6, -4).replace(/[^-]/g, "*"),
            id.slice(-3),
          ].join(""),
          entityId,
          entityType,
          qrCode,
        },
      });
      setSaved({ apiKey: id, qrCode, label: value.label });
    },
    onSubmitInvalid: () => reset(),
  });
  const validators = useValidators(validation, form.state.submissionAttempts);

  return { saved, error, form, validators };
};

const deleteApiKeyFn = requestFn(DeleteApiKeyDocument);

export const useDeleteApiKey = ({
  apiKeyId,
  entityId,
  entityType,
}: {
  apiKeyId: string;
  entityId: string;
  entityType: ApiKeyEntityType;
}) => {
  const complete = useClearParams();
  const queryClient = useQueryClient();
  const { error, mutateAsync, isPending } = useMutation({
    mutationFn: deleteApiKeyFn,
    onSuccess: async () => {
      queryClient.invalidateQueries(apiKeysQuery(entityId, entityType));
      await complete();
    },
  });

  return { error, isPending, onDelete: () => mutateAsync({ id: apiKeyId }) };
};
