import { z } from "zod";

export const addressSchema = z.object({
  display: z.string({ required_error: "Please enter address" }),
  latitude: z.number({ required_error: "Please enter latitude" }),
  longitude: z.number({ required_error: "Please enter longitude" }),
});

export type Address = z.infer<typeof addressSchema>;

export const addressResultSchema = addressSchema.extend({
  id: z.string(),
  type: z.literal("result"),
});

export type AddressResult = z.infer<typeof addressResultSchema>;

export const addressSuggestionSchema = addressSchema.extend({
  type: z.literal("suggestion"),
  session: z.string().optional(),
  id: z.string(),
});

export type AddressSuggestion = z.infer<typeof addressSuggestionSchema>;

export const addressLookupSchema = z.discriminatedUnion("type", [
  addressResultSchema,
  addressSuggestionSchema,
]);

export type AddressLookup = z.infer<typeof addressLookupSchema>;

export const currentLocation = () =>
  new Promise<{ latitude: number; longitude: number }>((resolve, reject) => {
    if (!navigator.geolocation) reject(new Error(`Geolocation not available`));
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      (error) => {
        reject(new Error(error.message));
      },
      { maximumAge: 1000 * 60 * 10, enableHighAccuracy: true },
    );
  });
