import { AnyFieldApi } from "@tanstack/react-form";
import { useRanger } from "@tanstack/react-ranger";
import clsx from "clsx";
import { ReactNode, useMemo, useRef } from "react";

import { Field, Label, fieldKinds } from "./parts";

export type RangeInputProps = {
  id?: string;
  className?: string;
  min: number;
  max: number;
  stepSize: number;
  tickSize: number;
  colour?: { text: string; bg: string; position: "before" | "after" };
  valueLabel?: (value: number) => string;
};

export const RangeInput = ({
  id,
  className,
  onChange,
  value,
  colour,
  valueLabel = (v) => `${v}`,
  tickSize,
  min,
  max,
  stepSize,
}: RangeInputProps & {
  onChange: (values: number) => void;
  value: number;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const ticks = useMemo(() => {
    const items = [min];
    for (let i = tickSize; i < max; i += tickSize) items.push(i);
    items.push(max);

    return items;
  }, [min, max, tickSize]);
  const ranger = useRanger({
    getRangerElement: () => ref.current,
    onChange: (instance) => onChange(instance.sortedValues[0] || 0),
    values: [value || 0],
    ticks,
    min,
    max,
    stepSize,
  });

  return (
    <div
      id={id}
      className={clsx("flex w-full flex-col px-3", className)}
      ref={ref}
    >
      <div className="relative h-6 w-full">
        {ranger
          .handles()
          .map(
            (
              { onKeyDownHandler, onMouseDownHandler, onTouchStart, value },
              idx,
            ) => (
              <button
                key={idx}
                type="button"
                onKeyDown={onKeyDownHandler}
                onMouseDown={onMouseDownHandler}
                onTouchStart={onTouchStart}
                role="slider"
                aria-valuemin={ranger.options.min}
                aria-valuemax={ranger.options.max}
                aria-valuenow={value}
                style={{ left: `${ranger.getPercentageForValue(value)}%` }}
                className="absolute bottom-0 flex -translate-x-1/2 flex-col items-center justify-center text-sm font-semibold"
              >
                <span>{valueLabel(value)}</span>
                <svg
                  viewBox="0 0 120 80"
                  className={clsx(
                    "h-2 w-3",
                    (value && colour?.text) || "text-gray-500",
                  )}
                >
                  <polygon points="0,0 60,80 120,0" fill="currentColor" />
                </svg>
              </button>
            ),
          )}
      </div>
      <div className="relative h-2 w-full overflow-hidden">
        {ranger.getSteps().map(({ left, width }, idx) => (
          <div
            key={idx}
            className={clsx(
              "absolute h-full",
              (colour?.position === "after" && idx === 1) ||
                (colour?.position === "before" && idx === 0)
                ? colour.bg
                : "bg-gray-300",
            )}
            style={{ left: `${left}%`, width: `${width}%` }}
          />
        ))}
      </div>
      <div className="relative h-6 w-full">
        {ranger.getTicks().map(({ key, value, percentage }) => (
          <div
            key={key}
            className="absolute flex -translate-x-1/2 flex-col items-center justify-center text-sm font-semibold text-gray-500"
            style={{ left: `${percentage}%` }}
          >
            <div className="h-1 w-px bg-gray-300" />
            <span>{value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export const RangeInputField = ({
  field,
  ...props
}: { field: AnyFieldApi } & RangeInputProps) => (
  <RangeInput
    id={field.name}
    value={field.state.value}
    onChange={(value) => field.handleChange(value)}
    {...props}
  />
);

export const RangeField = ({
  field,
  label,
  fieldKind,
  ...rest
}: {
  field: AnyFieldApi;
  label: ReactNode;
  fieldKind?: keyof typeof fieldKinds;
} & RangeInputProps) => (
  <Field kind={fieldKind}>
    <Label htmlFor={field.name}>{label}</Label>
    <RangeInputField field={field} {...rest} />
  </Field>
);
