import { IconDroplets } from "@tabler/icons-react";
import { useForm } from "@tanstack/react-form";
import {
  infiniteQueryOptions,
  queryOptions,
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { z } from "zod";

import { useValidators } from "../../hooks";
import { Word } from "../helpers";
import { request, requestFn } from "./base";
import {
  CreateProductDocument,
  DeleteProductDocument,
  ProductDocument,
  ProductsDocument,
  UpdateProductDocument,
} from "./operations.generated";

export const product: Word = {
  icon: IconDroplets,
  article: "a",
  singular: "product",
  plural: "products",
};

export const productsQuery = (customerId?: string) =>
  infiniteQueryOptions({
    queryKey: ["products", customerId],
    queryFn: ({ pageParam }) =>
      request(ProductsDocument, { customerId, cursor: pageParam || null }),
    getNextPageParam: (lastPage) => lastPage?.products.next,
    initialPageParam: "",
    select: (data) => data.pages.flatMap((p) => p.products.products),
  });

export type ProductItem = ReturnType<
  NonNullable<ReturnType<typeof productsQuery>["select"]>
>[number];

export const productQuery = (id: string) =>
  queryOptions({
    queryKey: ["product", id],
    queryFn: () => request(ProductDocument, { id }),
  });

export const usePrefetchProduct = () => {
  const queryClient = useQueryClient();
  return (productId: string) =>
    queryClient.prefetchQuery(productQuery(productId));
};

const validation = {
  label: z.string().min(1, "Please enter a product label"),
  colour: z.string().min(1, "Please select a colour"),
};

const createProductFn = requestFn(CreateProductDocument);

export const useCreateProduct = (customerId?: string) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { error, mutateAsync, reset } = useMutation({
    mutationFn: createProductFn,
    onSuccess: async (result) => {
      queryClient.setQueryData(productQuery(result.createProduct.id).queryKey, {
        __typename: "Query",
        product: result.createProduct,
      });
      queryClient.invalidateQueries(productsQuery(customerId));

      await navigate(
        customerId
          ? {
              to: "/customers/$customerId",
              replace: true,
              params: { customerId },
            }
          : {
              to: "/library/products",
              replace: true,
            },
      );
    },
  });

  const form = useForm({
    defaultValues: {
      label: "",
      colour: "",
    },
    onSubmit: ({ value }) =>
      mutateAsync({
        id: crypto.randomUUID(),
        customerId,
        fields: {
          label: value.label,
          colour: value.colour,
        },
      }),
    onSubmitInvalid: () => reset(),
  });
  const validators = useValidators(validation, form.state.submissionAttempts);

  return { error, form, validators };
};

const updateProductFn = requestFn(UpdateProductDocument);

export const useUpdateProduct = (id: string, customerId?: string) => {
  const navigate = useNavigate();
  const { data } = useSuspenseQuery(productQuery(id));
  const queryClient = useQueryClient();
  const { error, mutateAsync, reset } = useMutation({
    mutationFn: updateProductFn,
    onSuccess: async (result) => {
      queryClient.setQueryData(productQuery(id).queryKey, {
        __typename: "Query",
        product: result.updateProduct,
      });
      queryClient.invalidateQueries(
        productsQuery(result.updateProduct.customerId),
      );

      await navigate(
        customerId
          ? {
              to: "/customers/$customerId",
              replace: true,
              params: { customerId },
            }
          : {
              to: "/library/products",
              replace: true,
            },
      );
    },
  });

  const form = useForm({
    defaultValues: {
      label: data.product?.label || "",
      colour: data.product?.colour || "",
    },
    onSubmit: ({ value }) =>
      mutateAsync({
        id,
        fields: {
          label: value.label,
          colour: value.colour,
        },
      }),
    onSubmitInvalid: () => reset(),
  });
  const validators = useValidators(validation, form.state.submissionAttempts);

  return { data, error, form, validators };
};

const deleteProductFn = requestFn(DeleteProductDocument);

export const useDeleteProduct = (id: string, customerId?: string) => {
  const navigate = useNavigate();
  const { data } = useSuspenseQuery(productQuery(id));
  const queryClient = useQueryClient();
  const { error, mutateAsync, isPending } = useMutation({
    mutationFn: deleteProductFn,
    onSuccess: async (result) => {
      if (result.deleteProduct) {
        queryClient.invalidateQueries(productsQuery(data.product?.customerId));

        await navigate(
          customerId
            ? {
                to: "/customers/$customerId",
                replace: true,
                params: { customerId },
              }
            : {
                to: "/library/products",
                replace: true,
              },
        );
        queryClient.removeQueries(productQuery(id));
      }
    },
  });

  return { data, error, isPending, onDelete: () => mutateAsync({ id }) };
};
