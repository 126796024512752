import { useForm } from "@tanstack/react-form";
import {
  infiniteQueryOptions,
  queryOptions,
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { z } from "zod";

import { userName } from "@joy/shared-utils";

import { useClearParams, useValidators } from "../../hooks";
import { request, requestFn } from "./base";
import {
  CreateRoleDocument,
  DeleteRoleDocument,
  PermissionsDocument,
  RoleDocument,
  RoleEntityType,
  RoleInput,
  RolePermission,
  RolesDocument,
  UpdateRoleDocument,
  UserRolesDocument,
} from "./operations.generated";

type PermissionField<P extends RolePermission = RolePermission> = {
  name: P;
  label: string;
  description: (e?: RoleEntityType) => string;
  disabled: (values: Record<RolePermission, boolean>) => boolean;
  visible: RoleEntityType[];
  includes: RolePermission[];
};

export const permissionFields: PermissionField[] = [
  {
    name: "read",
    label: "View",
    description: (e) => (e === "tank" ? `this tank` : `tanks in this ${e}`),
    disabled: (values) => values.write || values.install || values.manage,
    visible: ["tank", "account", "customer"],
    includes: [],
  },
  {
    name: "write",
    label: "Edit",
    description: (e) => (e === "tank" ? `this tank` : `tanks in this ${e}`),
    disabled: (values) => values.manage,
    visible: ["tank", "account", "customer"],
    includes: ["read"],
  },
  {
    name: "install",
    label: "Install",
    description: (e) => `gauges in this ${e}`,
    disabled: (values) => values.manage,
    visible: ["account", "customer"],
    includes: ["read"],
  },
  {
    name: "manage",
    label: "Manage",
    description: (e) => `roles in this ${e}`,
    disabled: () => false,
    visible: ["account", "customer"],
    includes: ["read", "write", "install"],
  },
];

export const rolesQuery = (entityId: string, entityType: RoleEntityType) =>
  infiniteQueryOptions({
    queryKey: ["roles", entityId],
    queryFn: ({ pageParam }) =>
      request(RolesDocument, {
        entityId,
        entityType,
        limit: 50,
        cursor: pageParam || null,
      }),
    getNextPageParam: (lastPage) => lastPage?.roles.next,
    initialPageParam: "",
    select: (data) =>
      data.pages
        .flatMap((p) => p.roles.roles)
        .map((r) => ({
          ...r,
          user: { ...r.user, name: userName(r.user, { hideEmail: true }) },
        })),
  });

export type RoleItem = ReturnType<
  NonNullable<ReturnType<typeof rolesQuery>["select"]>
>[number];

export const userRolesQuery = (userId: string) =>
  infiniteQueryOptions({
    queryKey: ["roles", userId],
    queryFn: ({ pageParam }) =>
      request(UserRolesDocument, {
        userId,
        limit: 50,
        cursor: pageParam || null,
      }),
    getNextPageParam: (lastPage) => lastPage?.userRoles.next,
    initialPageParam: "",
    select: (data) =>
      data.pages
        .flatMap((p) => p.userRoles.roles)
        .map((r) => ({
          ...r,
          user: { ...r.user, name: userName(r.user, { hideEmail: true }) },
        })),
  });

export type UserRoleItem = ReturnType<
  NonNullable<ReturnType<typeof userRolesQuery>["select"]>
>[number];

export const permissionsQuery = () =>
  queryOptions({
    queryKey: ["permissions"],
    queryFn: () => request(PermissionsDocument, {}),
    select: (data) => data.permissions,
  });

export const roleQuery = (id: string) =>
  queryOptions({
    queryKey: ["role", id],
    queryFn: () => request(RoleDocument, { id }),
    select: (data) => data.role!,
  });

export const usePrefetchRole = () => {
  const queryClient = useQueryClient();
  return (roleId: string) => queryClient.prefetchQuery(roleQuery(roleId));
};

const validation = {
  entityType: z.enum(["tank", "account", "customer"]),
  entity: z.object(
    { id: z.string(), name: z.string() },
    { invalid_type_error: "Please select an item" },
  ),
  user: z.object(
    { id: z.string(), email: z.string().email() },
    { invalid_type_error: "Please select a user" },
  ),
  email: z.string().email(),
};

const createRoleFn = requestFn(CreateRoleDocument);

export const useCreateRole = (initial: Partial<RoleInput>) => {
  const onClose = useClearParams();
  const queryClient = useQueryClient();
  const { error, mutateAsync, reset } = useMutation({
    mutationFn: createRoleFn,
    onSuccess: async (result) => {
      queryClient.invalidateQueries(roleQuery(result.createRole.id));
      queryClient.invalidateQueries(
        rolesQuery(result.createRole.entityId, result.createRole.entityType),
      );
      queryClient.invalidateQueries(userRolesQuery(result.createRole.user.id));
      onClose();
    },
  });

  const form = useForm({
    defaultValues: {
      entityType: initial.entityType || "account",
      entity: initial.entityId ? { id: initial.entityId, name: "" } : null,
      user: initial.userEmail ? { id: "", email: initial.userEmail } : null,
      permissions: {
        read: true,
        install: false,
        write: false,
        manage: false,
      },
    },
    onSubmit: ({ value }) =>
      mutateAsync({
        id: crypto.randomUUID(),
        fields: {
          entityType: value.entityType,
          entityId: value.entity?.id || "",
          userEmail: value.user?.email || "",
          permissions: (
            [
              value.permissions.read ? "read" : undefined,
              value.permissions.install ? "install" : undefined,
              value.permissions.write ? "write" : undefined,
              value.permissions.manage ? "manage" : undefined,
            ] as const
          ).filter((p) => p !== undefined),
        },
      }),
    onSubmitInvalid: () => reset(),
  });
  const validators = useValidators(validation, form.state.submissionAttempts);

  return { error, form, validators };
};

const updateRoleFn = requestFn(UpdateRoleDocument);

export const useUpdateRole = (roleId: string) => {
  const complete = useClearParams();
  const queryClient = useQueryClient();
  const { data } = useSuspenseQuery(roleQuery(roleId));
  const { error, mutateAsync, reset } = useMutation({
    mutationFn: updateRoleFn,
    onSuccess: async (result) => {
      queryClient.invalidateQueries(roleQuery(result.updateRole.id));
      queryClient.invalidateQueries(
        rolesQuery(result.updateRole.entityId, result.updateRole.entityType),
      );
      queryClient.invalidateQueries(userRolesQuery(result.updateRole.user.id));
      await complete();
    },
  });

  const form = useForm({
    defaultValues: {
      permissions: {
        read: data.permissions.includes("read"),
        install: data.permissions.includes("install"),
        write: data.permissions.includes("write"),
        manage: data.permissions.includes("manage"),
      },
    },
    onSubmit: async ({ value }) => {
      await mutateAsync({
        id: roleId,
        permissions: (
          [
            value.permissions.read ? "read" : undefined,
            value.permissions.install ? "install" : undefined,
            value.permissions.write ? "write" : undefined,
            value.permissions.manage ? "manage" : undefined,
          ] as const
        ).filter((p) => p !== undefined),
      });
    },
    onSubmitInvalid: () => reset(),
  });
  const validators = useValidators(validation, form.state.submissionAttempts);

  return { data, error, form, validators };
};

const deleteRoleFn = requestFn(DeleteRoleDocument);

export const useDeleteRole = (roleId: string) => {
  const complete = useClearParams();
  const queryClient = useQueryClient();
  const { data } = useSuspenseQuery(roleQuery(roleId));
  const { error, mutateAsync, isPending } = useMutation({
    mutationFn: deleteRoleFn,
    onSuccess: async (deleted) => {
      if (deleted.deleteRole) {
        queryClient.invalidateQueries(
          rolesQuery(data.entityId, data.entityType),
        );
        queryClient.invalidateQueries(userRolesQuery(data.user.id));
        await complete();
      }
    },
  });

  return {
    data,
    error,
    isPending,
    onDelete: () => mutateAsync({ id: roleId }),
  };
};
