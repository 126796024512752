import { IconBuildingFactory } from "@tabler/icons-react";
import { useForm } from "@tanstack/react-form";
import {
  infiniteQueryOptions,
  queryOptions,
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { z } from "zod";

import { awaitBatch, mapObject, sortField } from "@joy/shared-utils";

import { useValidators } from "../../hooks";
import { Word } from "../helpers";
import { request, requestFn } from "./base";
import { gaugeQuery } from "./gauges";
import {
  AccountDocument,
  AccountInput,
  AccountProduct,
  AccountTanksDocument,
  AccountsDocument,
  CreateAccountDocument,
  DeleteAccountDocument,
  UpdateAccountDocument,
  UpdateGaugeDocument,
  VerifyAccountDocument,
} from "./operations.generated";
import { tankQuery } from "./tanks";

export const account: Word = {
  icon: IconBuildingFactory,
  article: "an",
  singular: "account",
  plural: "accounts",
};

export const accountsQuery = (enable = true) =>
  infiniteQueryOptions({
    queryKey: ["accounts"],
    queryFn: ({ pageParam }) =>
      request(AccountsDocument, { limit: 50, cursor: pageParam || null }),
    getNextPageParam: (lastPage) => lastPage?.accounts.next,
    initialPageParam: "",
    enabled: enable,
    select: (data) =>
      sortField(
        data.pages.flatMap((p) => p.accounts.accounts),
        {
          key: "updatedAt",
          dir: "desc",
          unique: "id",
        },
      ),
  });

export const accountQuery = (id: string) =>
  queryOptions({
    queryKey: ["account", id],
    queryFn: () => request(AccountDocument, { id }),
    select: (data) => ({ ...data.account!, permissions: data.accountAccess }),
  });

export const accountTanksQuery = (id: string) =>
  infiniteQueryOptions({
    queryKey: ["account-tanks", id],
    queryFn: ({ pageParam }) =>
      request(AccountTanksDocument, {
        id,
        limit: 50,
        cursor: pageParam || null,
      }),
    getNextPageParam: (lastPage) => lastPage?.account?.tanks.next,
    initialPageParam: "",
    select: (data) =>
      data.pages.flatMap(
        (p) =>
          p.account?.tanks.tanks.map((t) => ({
            ...t,
            level: t.levels.levels[0] || undefined,
          })) || [],
      ),
  });

const validation = {
  name: z.string().min(1, "Please enter an account name"),
  product: z.enum(["JoyGauge", "ProGauge", "SmartFill", "Colibri"], {
    invalid_type_error: "Please select a product",
  }),
  plan: z.object(
    { id: z.string(), code: z.string(), description: z.string() },
    { invalid_type_error: "Please select a plan" },
  ),
  customer: z.object(
    {
      id: z.string(),
      name: z.string(),
    },
    { invalid_type_error: "Please select a customer" },
  ),
};

const configValidation = {
  url: z.string().url(),
  userId: z.object(
    { value: z.string() },
    { invalid_type_error: "Please select a user" },
  ),
  timezone: z.object(
    { value: z.string() },
    { invalid_type_error: "Please select a timezone" },
  ),
  maximumLevel: z
    .string({ invalid_type_error: "Must be a number" })
    .regex(/^\d+(\.\d+)?$/, "Must be a number"),
  reference: z
    .string({ invalid_type_error: "Please enter a reference" })
    .min(1, "Please enter a reference"),
  secret: z
    .string({ invalid_type_error: "Please enter a secret" })
    .min(1, "Please enter a secret"),
};

const createAccountFn = requestFn(CreateAccountDocument);

export const useCreateAccount = (initial: Partial<AccountInput>) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { error, mutateAsync, reset } = useMutation({
    mutationFn: createAccountFn,
    onSuccess: async (result) => {
      queryClient.setQueryData(accountQuery(result.createAccount.id).queryKey, {
        __typename: "Query",
        account: result.createAccount,
        accountAccess: ["read", "write", "install", "manage"],
      });
      await navigate({
        to: "/accounts/$accountId",
        params: { accountId: result.createAccount.id },
        search: { action: "config" },
      });
      queryClient.invalidateQueries(accountsQuery());
    },
  });

  const form = useForm({
    defaultValues: {
      name: "",
      customer: initial.customerId
        ? { id: initial.customerId, name: "" }
        : null,
      plan: null as { id: string; code: string; description: string } | null,
      product: null as AccountProduct | null,
      config: {},
    },
    onSubmit: ({ value }) =>
      mutateAsync({
        id: crypto.randomUUID(),
        fields: {
          name: value.name,
          customerId: value.customer?.id,
          planId: value.plan?.id,
          product: value.product,
          config: {
            __typename: `${value.product}Config`,
            ...value.config,
          },
        },
      }),
    onSubmitInvalid: () => reset(),
  });
  const validators = useValidators(validation, form.state.submissionAttempts);

  return { error, form, validators };
};

const verifyAccountFn = requestFn(VerifyAccountDocument);

const updateAccountFn = requestFn(UpdateAccountDocument);

const updateGaugeFn = requestFn(UpdateGaugeDocument);

export const useUpdateAccountConfig = (accountId: string) => {
  const navigate = useNavigate();
  const { data } = useSuspenseQuery(accountQuery(accountId));
  const queryClient = useQueryClient();
  const updateGauge = useMutation({
    mutationFn: updateGaugeFn,
    onSuccess: async (result) => {
      queryClient.invalidateQueries(gaugeQuery(result.updateGauge.id));
      queryClient.invalidateQueries(tankQuery(result.updateGauge.id));
    },
  });
  const verify = useMutation({
    mutationFn: verifyAccountFn,
    onSuccess: async () => {
      queryClient.invalidateQueries(accountTanksQuery(accountId));
      await navigate({
        to: "/accounts/$accountId",
        params: { accountId },
        replace: true,
      });
    },
  });
  const { error, mutateAsync, reset } = useMutation({
    mutationFn: updateAccountFn,
    onSuccess: async (result) => {
      queryClient.setQueryData(
        accountQuery(result.updateAccount.id).queryKey,
        (existing) => {
          if (!existing) return undefined;
          return {
            ...existing,
            account: result.updateAccount,
          };
        },
      );
    },
  });

  const form = useForm({
    defaultValues: {
      gauges:
        (data.config?.__typename === "JoyGaugeConfig" &&
          data.config.gauges.gauges) ||
        [],
      url:
        (data.config?.__typename === "ColibriConfig" && data.config.url) || "",
      reference:
        (data.config?.__typename === "SmartFillConfig" &&
          data.config.reference) ||
        "",
      secret:
        (data.config?.__typename === "SmartFillConfig" && data.config.secret) ||
        "",
      userId:
        (data.config?.__typename === "ProGaugeConfig" &&
          data.config.userId && { value: data.config.userId }) ||
        null,
      timezone:
        (data.config?.__typename === "ProGaugeConfig" &&
          data.config.timezone && { value: data.config.timezone }) ||
        undefined,
      maximum:
        data.config?.__typename === "ProGaugeConfig" &&
        mapObject(data.config.maximum || {}, (v) => v.toString()),
    },
    onSubmit: async ({ value }) => {
      if (data.config?.__typename === "JoyGaugeConfig") {
        const existingGaugeIds = data.config.gauges.gauges.map((g) => g.id);
        const updatedGaugeIds = value.gauges.map((g) => g.id);

        const toRemove = existingGaugeIds
          .filter((id) => !updatedGaugeIds.includes(id))
          .map((id) =>
            updateGauge.mutateAsync({ id, fields: { accountId: null } }),
          );
        const toAdd = updatedGaugeIds
          .filter((id) => !existingGaugeIds.includes(id))
          .map((id) =>
            updateGauge.mutateAsync({
              id,
              fields: { accountId, customerId: data.customer?.id },
            }),
          );

        const results = await awaitBatch([...toRemove, ...toAdd]);
        const error = results.find((r) => r instanceof Error);
        if (error) throw error;

        queryClient.setQueryData(
          accountQuery(accountId).queryKey,
          (existing) => {
            if (!existing) return undefined;
            return {
              ...existing,
              account: existing.account
                ? {
                    ...existing.account,
                    config: existing.account.config
                      ? {
                          ...existing.account.config,
                          gauges: {
                            __typename: "GaugeList" as const,
                            gauges: value.gauges,
                          },
                        }
                      : undefined,
                  }
                : undefined,
            };
          },
        );
      } else {
        let config = {};
        switch (data.config?.__typename) {
          case "ColibriConfig":
            config = { url: value.url };
            break;
          case "SmartFillConfig":
            config = { reference: value.reference, secret: value.secret };
            break;
          case "ProGaugeConfig":
            config = {
              userId: value.userId?.value,
              timezone: value.timezone?.value,
              maximum: mapObject(value.maximum || {}, (v) => parseFloat(v)),
            };
            break;
        }

        await mutateAsync({
          id: accountId,
          fields: {
            config,
          },
        });
      }
      await verify.mutateAsync({
        id: accountId,
      });
    },
    onSubmitInvalid: () => {
      reset();
      verify.reset();
    },
  });
  const validators = useValidators(
    configValidation,
    form.state.submissionAttempts,
  );

  return {
    data,
    error: error || verify.error || updateGauge.error,
    form,
    validators,
  };
};

export type AccountConfigContentProps = Pick<
  ReturnType<typeof useUpdateAccountConfig>,
  "data" | "form" | "validators"
>;

export const useUpdateAccount = (accountId: string) => {
  const navigate = useNavigate();
  const { data } = useSuspenseQuery(accountQuery(accountId));
  const queryClient = useQueryClient();
  const { error, mutateAsync, reset } = useMutation({
    mutationFn: updateAccountFn,
    onSuccess: async (result) => {
      queryClient.setQueryData(
        accountQuery(result.updateAccount.id).queryKey,
        (existing) => {
          if (!existing) return undefined;
          return {
            ...existing,
            account: result.updateAccount,
          };
        },
      );
      queryClient.setQueryData(accountsQuery().queryKey, (existing) => {
        if (!existing) return undefined;

        return {
          ...existing,
          pages: existing.pages.map((p) => ({
            ...p,
            accounts: {
              ...p.accounts,
              accounts: p.accounts.accounts.map((u) => {
                if (u.id === result.updateAccount?.id)
                  return result.updateAccount;
                return u;
              }),
            },
          })),
        };
      });
      await navigate({ to: "/accounts/$accountId", params: { accountId } });
    },
  });

  const form = useForm({
    defaultValues: {
      name: data.name || "",
      customer: data.customer
        ? { id: data.customer.id, name: data.customer.name }
        : null,
      plan: data.plan
        ? {
            id: data.plan.id,
            code: data.plan.code,
            description: data.plan.description,
          }
        : null,
    },
    onSubmit: async ({ value }) => {
      await mutateAsync({
        id: accountId,
        fields: {
          name: value.name,
          customerId: value.customer?.id,
          planId: value.plan?.id,
        },
      });
    },
    onSubmitInvalid: () => reset(),
  });
  const validators = useValidators(validation, form.state.submissionAttempts);

  return { data, error, form, validators };
};

const deleteAccountFn = requestFn(DeleteAccountDocument);

export const useDeleteAccount = (accountId: string) => {
  const navigate = useNavigate();
  const { data } = useSuspenseQuery(accountQuery(accountId));
  const queryClient = useQueryClient();
  const { error, mutateAsync, isPending } = useMutation({
    mutationFn: deleteAccountFn,
    onSuccess: async (result, { id }) => {
      if (result.deleteAccount) {
        queryClient.setQueryData(accountsQuery().queryKey, (existing) => {
          if (!existing) return undefined;

          return {
            ...existing,
            pages: existing.pages.map((p) => ({
              ...p,
              accounts: {
                ...p.accounts,
                accounts: p.accounts.accounts.filter((u) => u.id !== id),
              },
            })),
          };
        });

        await navigate({ to: "/accounts" });
        queryClient.removeQueries(accountQuery(id));
      }
    },
  });

  return {
    data,
    error,
    isPending,
    onDelete: () => mutateAsync({ id: accountId }),
  };
};
