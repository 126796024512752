import { dayOrder, range, timezoneOptions } from "@joy/shared-utils";

import {
  Button,
  CheckInputField,
  ComboField,
  Field,
  Form,
  Label,
  Loading,
  RadioField,
  TextField,
  fieldParts,
} from "../../components";
import { AccountScheduleContentProps } from "../../data";

const intervalLabels = {
  Week: {
    title: "Weekly",
    description: "Every week on selected days",
  },
  Month: {
    title: "Monthly",
    description: "Every month on a specific day",
  },
};

export const AccountScheduleFields = ({
  form,
  validators,
  users,
  onClose,
}: AccountScheduleContentProps & { onClose: () => void }) => (
  <Form
    kind="group"
    onSubmit={async () => {
      await form.handleSubmit();
      form.reset();
      onClose();
    }}
    onReset={() => {
      form.reset();
      onClose();
    }}
  >
    <form.Field
      name="interval"
      validators={validators.interval}
      children={(field) => (
        <RadioField
          field={field}
          label="Schedule Type"
          options={validators.interval.onSubmit._def.values}
          optionLabel={(o) => (
            <div>
              <div>{intervalLabels[o].title}</div>
              <div className="text-xs text-gray-500">
                {intervalLabels[o].description}
              </div>
            </div>
          )}
        />
      )}
    />
    <form.Subscribe
      selector={(v) => v.values.interval}
      children={(interval) => {
        switch (interval) {
          case "Week":
            return (
              <Field>
                <Label>Scheduled Days</Label>
                <div className={fieldParts.checks}>
                  {dayOrder.map((day) => (
                    <form.Field
                      key={day}
                      name={`days.${day}`}
                      children={(field) => (
                        <CheckInputField field={field} label={day} />
                      )}
                    />
                  ))}
                </div>
              </Field>
            );
          case "Month":
            return (
              <form.Field
                name="date"
                validators={validators.date}
                children={(field) => (
                  <TextField
                    field={field}
                    label="Day of Month"
                    inputMode="decimal"
                  />
                )}
              />
            );
        }
      }}
    />
    <form.Field
      name="startTime"
      validators={validators.time}
      children={(field) => (
        <ComboField
          field={field}
          label="Scheduled Time"
          options={range(0, 23).map((hour) => ({ hour }))}
          accessors={[(o) => [`${o.hour}`, `${o.hour % 12}`]]}
          optionKey={(o) => o.hour}
          optionLabel={(o) => {
            if (o.hour === 0) return "Midnight";
            if (o.hour === 12) return "Midday";
            return `${o.hour % 12} ${o.hour >= 12 ? "pm" : "am"}`;
          }}
          immediate
        />
      )}
    />
    <form.Field
      name="timezone"
      validators={validators.timezone}
      children={(field) => (
        <ComboField
          field={field}
          label="Timezone"
          options={timezoneOptions}
          accessors={[(o) => o.value]}
          optionKey={(o) => o.value}
          optionLabel={(o) => o.value}
          immediate
        />
      )}
    />
    <form.Field
      name="users"
      validators={validators.users}
      children={(field) => (
        <ComboField
          field={field}
          by={(a, b) => a?.email === b?.email}
          label="Notification Emails"
          options={users?.map((u) => u.user) || []}
          accessors={[(o) => o.email]}
          optionKey={(o) => o.id}
          optionLabel={(o) => o.email}
          optionCreate={(value) => ({ id: value, name: value, email: value })}
          multiple
          immediate
        />
      )}
    />
    <div className="flex justify-end gap-2">
      <Button variant="cancel" text="Cancel" type="reset" />
      <Button variant="action" text="Save" type="submit" />
    </div>
    <Loading show={form.state.isSubmitted} text="Saving..." />
  </Form>
);
